<template>
    <div>
        <div class="item_add_list_wrap">
            <ul class="entry_list_wrap">
                <li class="list_item" v-for="(item, index) in itemData" :key="`item${item.i_idx}`"
                    @click="selectItem(item)">
                    <div class="thumbnail" :style="`background-image: url(${returnThumbnail(item)})`">
                        <div class="card_category cc_category">{{ item.ic_name_eng }}</div>
                        <div class="card_category cc_real" v-if="item.i_type ==='real'">{{ $t('real') }}</div>
                        <div class="card_category cc_real play_icon"
                             v-if="item.im_type === 'video'|| item.im_type==='audio'">
                            <img class="icon play" src="@/assets/image/main/icon_play.png" alt="">
                        </div>
                    </div>
                    <div class="card_detail">
                        <div class="card_title">{{ item.i_name }}</div>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListItemAddLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: ['setSelectItem'],
    provide() {
        return {}
    },
    props: {
        itemData: {default: () => []},
    },
    data() {
        return {
            entryItemLists: 20,

            searchMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        selectItem(item) {
            this.setSelectItem(item);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return `${data.ItemFile.Item[0].org_url}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
